<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent :records="tableData" @resetHandler="reset" @searchHandler="doSearch" @queryByIdHandler="queryById"
      @deleteByIdHandler="deleteById" @batchDeleteHandler="batchDelete" @addHandler="saveTripEventLog"
      @exportExl="exportExl" :loading="loading" :EShow="true" :showAdd="false" :showDelete="false"
      :exportLoading="exportLoading">
      <template v-slot:search>
        <el-form-item label="车牌号:">
          <el-input v-model="search.carNum" placeholder="请输入车牌号"></el-input>
        </el-form-item>
        <el-form-item label="司机姓名:">
          <el-input v-model="search.driverName" placeholder="请输入司机姓名"></el-input>
        </el-form-item>
        <el-form-item label="司机电话:">
          <el-input v-model="search.driverMobile" placeholder="请输入司机电话"></el-input>
        </el-form-item>
        <el-form-item label="乘客联系电话:">
          <el-input v-model="search.mobile" placeholder="请输入乘客联系电话"></el-input>
        </el-form-item>

        <el-form-item label="下车时间:">
          <el-date-picker v-model="TimeArr" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="pickerOptions" :default-time="defaultTimeRange"
            format="yyyy-MM-dd HH:mm">
          </el-date-picker>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="carNum" label="车牌号"></el-table-column>
        <el-table-column prop="driverName" label="司机姓名"></el-table-column>
        <el-table-column prop="driverMobile" label="司机电话"></el-table-column>
        <el-table-column prop="userName" label="乘客姓名"></el-table-column>
        <el-table-column prop="mobile" label="乘客联系电话"></el-table-column>
        <el-table-column prop="startTime" label="下车时间">
          <template slot-scope="scope">
            {{ dateFormatType(scope.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="destination" label="下车地点"></el-table-column>
        <el-table-column prop="estimatedAmountStr" label="金额(元)"></el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
<script>
import listPage from "../../base/listPage.vue";
import TripEventLogApi from "@/api/TripEventLogApi";
export default {
  name: "TripEventLogList",
  extends: listPage,
  data() {
    let that = this;
    return {
      tableData: {},
      exportLoading: false,
      search: {
        carNum: "",
        driverName: "",
        driverMobile: "",
        mobile: "",
        startTime: "",
        endTime: "",
      },
      TimeArr: [],
      page: {},
      defaultTimeRange: ['00:00:00', '00:00:00'],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          that.pickerMinDate = minDate.getTime();
          if (maxDate) {
            that.pickerMinDate = null;
          }
        },
        // disabledDate: (time) => {
        //   if (that.pickerMinDate) {
        //     const day30 = (30 - 1) * 24 * 3600 * 1000;
        //     let maxTime = that.pickerMinDate + day30;
        //     if (maxTime > new Date()) {
        //       maxTime = new Date();
        //       return (
        //         time.getTime() > maxTime ||
        //         time.getTime() < that.pickerMinDate - day30
        //       );
        //     } else {
        //       return (
        //         maxTime < time.getTime() ||
        //         time.getTime() < that.pickerMinDate - day30
        //       );
        //     }
        //   }

        //   return time.getTime() > Date.now();
        // },
      },
    };

  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.TimeArr = [];
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      if (this.TimeArr == null) {
        this.search.startTime = "";
        this.search.endTime = "";
      } else if (this.TimeArr && this.TimeArr.length != 0) {
        this.search.startTime = this.TimeArr[0];
        this.search.endTime = this.TimeArr[1];
      }
      TripEventLogApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveTripEventLog() {
      this.navTo("/saveTripEventLog");
    },
    queryById(id) {
      TripEventLogApi.queryTripEventLogById(id).then((resp) => {
        if (resp.code === '200') {
          this.queryParam("saveTripEventLog", resp.data);
        }
      });
    },
    deleteById(id) {
      TripEventLogApi.deleteTripEventLog(id).then((resp) => {
        if (resp.code === '200') {
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    batchDelete(params) {
      TripEventLogApi.batchDeleteTripEventLog(params).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    exportExl() {
      let that = this;
      if (this.exportLoading) {
        this.$message("请勿重复操作");
        return;
      }
      this.exportLoading = true;
      TripEventLogApi.exportTripEventLog(this.search)
        .then((res) => {
          this.exportLoading = false;
          if (res.status == 200) {
            let blob = new Blob([res.data]);
            let cd =
              res.headers["content-disposition"] ||
              res.headers["Content-Disposition"];
            if (cd == undefined || cd == null) {
              let reader = new FileReader();
              reader.addEventListener("loadend", function () {
                let resp = JSON.parse(reader.result);
                that.$errorMsg(resp.msg);
              });
              reader.readAsText(blob, "utf-8");
              return;
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            let fileName = decodeURIComponent(
              cd.replace("attachment; filename=", "")
            );
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.$successMsg("导出成功");
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err);
          this.exportLoading = false;
        });
    },
    dateFormatType(val) {
      if (!val) {
        return "";
      }
      var date = new Date(val);
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // js从0开始取
      var date1 = date.getDate();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var second = date.getSeconds();
      if ((month + "").length === 1) {
        month = "0" + month;
      }
      if ((date1 + "").length === 1) {
        date1 = "0" + date1;
      }
      if ((hour + "").length === 1) {
        hour = "0" + hour;
      }
      if ((minutes + "").length === 1) {
        minutes = "0" + minutes;
      }
      if ((second + "").length === 1) {
        second = "0" + second;
      }

      return (
        year +
        "-" +
        month +
        "-" +
        date1 +
        "  " +
        hour +
        ":" +
        minutes +
        ":" +
        second
      );
    },
  },
};
</script>
  <!--页面代码从这里结束拷贝-->